import React from "react";
import { LeftMenuItem } from "../../../interfaces";
import { PAGE_URL, USER_ROLES_CODE } from "../../../utils";
import {
  HomeIcon,
  SearchIcon,
  SettingsIcon,
  RoleIcon,
  MenuIcon,
  AnnotationIcon,
} from "../../../assets/icons";

const CLINICIAN_LEFT_MENU_ITEMS: LeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Dashboard",
  },
  {
    link: PAGE_URL.PATIENTS_LIST,
    icon: <MenuIcon />,
    width: 20,
    height: 20,
    title: "Subjects",
  },
  {
    link: PAGE_URL.SEARCH.LAYOUT,
    icon: <SearchIcon />,
    width: 27,
    height: 24,
    title: "Search",
  },
];

const MANAGER_AND_DATA_ADMIN_LEFT_MENU_ITEMS: LeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Dashboard",
  },
  {
    link: PAGE_URL.PATIENTS_LIST,
    icon: <MenuIcon />,
    width: 20,
    height: 20,
    title: "Subjects",
  },
  {
    link: PAGE_URL.SEARCH.LAYOUT,
    icon: <SearchIcon />,
    width: 27,
    height: 24,
    title: "Search",
  },
  {
    link: PAGE_URL.SETTINGS,
    icon: <SettingsIcon />,
    width: 20,
    height: 20,
    // disable: true,
    title: "Settings",
  },
];

const ANNOTATION_MENU: LeftMenuItem = {
  link: PAGE_URL.ANNOTATION,
  icon: <AnnotationIcon />,
  width: 20,
  height: 20,
  title: "Annotation",
};

const ENTERPRISE_ADMIN_LEFT_MENU_ITEMS: LeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Dashboard",
  },
  {
    link: PAGE_URL.ROLE_MAPPING,
    icon: <RoleIcon />,
    width: 18,
    height: 20,
    title: "Role Map",
  },
  {
    link: PAGE_URL.CONFIGURATIONS,
    icon: <SettingsIcon />,
    width: 20,
    height: 20,
    title: "Configuration",
  },
];

const SUPER_ADMIN_LEFT_MENU_ITEMS: LeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Dashboard",
  },
];

export const getAppMenuItems = (roleCode: string) => {
  const menu = [] as LeftMenuItem[];
  const displayAnnotation = false;
  switch (roleCode) {
    case USER_ROLES_CODE.CLINICIAN:
      menu.push(...CLINICIAN_LEFT_MENU_ITEMS);
      break;
    case USER_ROLES_CODE.DATA_ADMIN:
      menu.push(...MANAGER_AND_DATA_ADMIN_LEFT_MENU_ITEMS);
      if (displayAnnotation) menu.push(ANNOTATION_MENU);
      break;
    case USER_ROLES_CODE.MANAGER:
      menu.push(...MANAGER_AND_DATA_ADMIN_LEFT_MENU_ITEMS);
      if (displayAnnotation) menu.push(ANNOTATION_MENU);
      break;
    case USER_ROLES_CODE.ENTERPRISE_ADMIN:
      menu.push(...ENTERPRISE_ADMIN_LEFT_MENU_ITEMS);
      break;
    case USER_ROLES_CODE.SUPER_ADMIN:
      menu.push(...SUPER_ADMIN_LEFT_MENU_ITEMS);
      break;
    default:
      break;
  }
  return menu;
};
