import React from 'react';
import {
  VitalSignsAndPhysicalMeasurements,
  DemographicsCard,
} from './patient-insight-summary';
import { IPatient } from '../../../interfaces';

interface Props {
  patientInfo: IPatient;
}

export const Summary = ({ patientInfo }: Props) => {
  const { demographics } = patientInfo;
  return (
    <div>
      {demographics && <DemographicsCard demographics={demographics} />}
      {/* <VitalSignsAndPhysicalMeasurements /> */}
    </div>
  );
};
