import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientListInfo, RecentDocument } from "../../api";
import { ProjectDetail, ProjectMeta, ProjectUser } from "../../interfaces";

interface ProjectSlice {
  detail: {
    detail: ProjectDetail;
    patients: PatientListInfo[];
  };
  assignPatients: {
    patientIds: number[];
    alreadyAssignedProjects: number[];
  };
  documentDetail: RecentDocument;
  projectConfiguration: ProjectMeta;
  isProjectAssigned: boolean;
}

export const projectInitialState: ProjectSlice = {
  detail: {
    detail: {} as ProjectDetail,
    patients: [] as PatientListInfo[],
  },
  assignPatients: {
    patientIds: [] as number[],
    alreadyAssignedProjects: [] as number[],
  },
  documentDetail: {} as RecentDocument,
  projectConfiguration: {} as ProjectMeta,
  isProjectAssigned: false,
};

export const projectSlice = createSlice({
  name: "project",
  initialState: projectInitialState,
  reducers: {
    setProjectDetail: (state: ProjectSlice, { payload }: PayloadAction<ProjectDetail>) => {
      state.detail.detail = { ...state.detail.detail, ...payload };
    },
    setProjectUsers: (state: ProjectSlice, { payload }: PayloadAction<ProjectUser[]>) => {
      state.detail.detail.users = [...payload];
    },
    setProjectPatients: (state: ProjectSlice, { payload }: PayloadAction<PatientListInfo[]>) => {
      state.detail.patients = [...payload];
    },
    updateProjectUser: (state: ProjectSlice, { payload }: PayloadAction<ProjectUser>) => {
      state.detail.detail.users = state.detail.detail.users.map((item) => {
        if (item.id === payload.id) return { ...payload };
        else return { ...item };
      });
    },
    updateAssignPatientsList: (
      state: ProjectSlice,
      { payload }: PayloadAction<{ patientIds: number[]; alreadyAssignedProjects: number[] }>
    ) => {
      state.assignPatients = {
        patientIds: [...payload.patientIds],
        alreadyAssignedProjects: [...payload.alreadyAssignedProjects],
      };
    },
    setProjectDocumentDetail: (state: ProjectSlice, { payload }: PayloadAction<RecentDocument>) => {
      state.documentDetail = { ...payload };
    },
    removeProjectDocumentDetail: (state: ProjectSlice) => {
      state.documentDetail = {} as RecentDocument;
    },
    setProjectConfiguration: (state: ProjectSlice, { payload }: PayloadAction<ProjectMeta>) => {
      state.projectConfiguration = { ...payload };
    },
    removeProjectConfiguration: (state: ProjectSlice) => {
      state.projectConfiguration = {} as ProjectMeta;
    },
    setIsProjectAssigned: (state: ProjectSlice, { payload }: PayloadAction<boolean>) => {
      state.isProjectAssigned = payload;
    },
  },
});

export const {
  setProjectDetail,
  setProjectUsers,
  updateProjectUser,
  setProjectPatients,
  updateAssignPatientsList,
  setProjectDocumentDetail,
  removeProjectDocumentDetail,
  setProjectConfiguration,
  removeProjectConfiguration,
  setIsProjectAssigned,
} = projectSlice.actions;

export default projectSlice.reducer;
