import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input } from "antd";
import { AddEditProjectProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { projectService } from "../../../api";
import { useDispatch } from "react-redux";
import { addProject, updateProject } from "../../../store";
import { MandatoryFieldsText, TextEditor } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

enum FORM_INPUT {
  PROJECT_NAME = "name",
  SOURCE_DATA_LOCATION = "bucketName",
}

const AddEditProject = (props: AddEditProjectProps) => {
  const { visibility, isEdit, editObj, clientId, handleClose } = props;
  const dispatch = useDispatch();
  const [form] = useForm();
  const [disableSave, setDisableSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [projectDescription, setProjectDesscription] = useState("");

  const isButtonDisabled = useMemo(() => {
    if (isEdit) {
      const isProjectDescriptionEdited =
        isEdit && editObj.Description !== projectDescription;
      return disableSave && !isProjectDescriptionEdited;
    } else {
      return !projectDescription || disableSave;
    }
  }, [disableSave, editObj.Description, isEdit, projectDescription]);

  useEffect(() => {
    const { Name, Description, s3Folder } = editObj;
    form.setFieldsValue({
      name: Name,
      description: Description,
      bucketName: s3Folder,
    });
    setProjectDesscription(Description);
  }, [form, editObj]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleEditSubmit = async (e: any) => {
    const name = e[FORM_INPUT.PROJECT_NAME];
    const s3Folder = e[FORM_INPUT.SOURCE_DATA_LOCATION];
    const description = projectDescription;
    const { data } = await projectService.editOne(clientId, editObj.Id, {
      name,
      s3Folder,
      description,
    });
    dispatch(updateProject(data.data));
  };

  const handleAddSubmit = async (e: any) => {
    const name = e[FORM_INPUT.PROJECT_NAME];
    const s3Folder = e[FORM_INPUT.SOURCE_DATA_LOCATION];
    const description = projectDescription;
    const { data } = await projectService.addOne(clientId, {
      name,
      s3Folder,
      description,
    });
    dispatch(addProject(data.data));
  };

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      if (isEdit) await handleEditSubmit(e);
      else await handleAddSubmit(e);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className="autonomizeModal addClient edit-project"
      centered
      width={587}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{isEdit ? "Edit Project" : "Add Project"}</h1>
      </div>
      <div className="addClient-content">
        <Form
          form={form}
          onFinish={handleSubmit}
          onFieldsChange={handleFormChange}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Project Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input />
          </Form.Item>
          <div className="text-editor">
            <h3>Project Description</h3>
            <TextEditor
              displayToolbar
              text={projectDescription}
              setText={setProjectDesscription}
              placeholder="Please enter and style project description here"
            />
          </div>
          <Form.Item
            className="m-t"
            name="bucketName"
            label="Project Source Data Location"
            rules={[
              { required: true, message: "Please enter S3 Folder name" },
              {
                message:
                  "Only alphabets, numbers, hyphen and underscore allowed",
                validator: (_, value) => {
                  if (/^[a-zA-Z0-9-_]+$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("error");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="tr mandatory-text">
            <MandatoryFieldsText />
            <Button
              className="outline"
              htmlType="submit"
              disabled={isButtonDisabled}
              loading={loading}
            >
              {isEdit ? "Save" : "Add Project"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditProject;
