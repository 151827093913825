import React, { useEffect, useState } from "react";
import { usePagination } from "../../../hooks";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { QuestionAnsweringService } from "../../../api/questionAnswering";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { IPatient } from "../../../interfaces";
import "./askQuestion.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  resetQuestionAnswerState,
  updateDeleteConfirmation,
} from "../../../store";
import { QAHistoryList } from "./QAHistoryList";
import { withAuthorization } from "../../../components/hoc/withAuthorization";

const AuthorizedQAHistoryList = withAuthorization(QAHistoryList);

interface Props {
  patientInfo: IPatient;
  setAskAQuestionLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QAHistory = ({ patientInfo, setAskAQuestionLoading }: Props) => {
  const [qaHistory, setQAHistory] = useState([] as IQAHistory[]);
  const [totalQA, setTotalQA] = useState(0);
  const { onPaginationChange, page, pageSize } = usePagination();
  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(true);
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );

  useEffect(() => {
    const clearHistory = async () => {
      try {
        await QuestionAnsweringService.clearHistory(patientInfo.patientId);
        setQAHistory([]);
        dispatch(updateDeleteConfirmation(false));
        dispatch(resetQuestionAnswerState(patientInfo.patientId.toString()));
        openNotificationWithIcon("", "History deleted successfully", "success");
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    };
    if (isDeleteConfirmed) clearHistory();
  }, [dispatch, isDeleteConfirmed, patientInfo.patientId]);

  const handleRefreshClick = () => {
    setQAHistory([]);
    onPaginationChange(1, pageSize);
    setCallApi(true);
  };

  return (
    <div className="ask-question-right-container">
      <div className="flex jcsb aic m-b">
        <h4 className="mb-0">Chat History</h4>
        <div className="flex aic gp">
          <a title="Refresh" onClick={handleRefreshClick}>
            <ReloadOutlined /> Refresh
          </a>
          {qaHistory.length !== 0 && (
            <a
              title="Clear Chat History"
              onClick={() =>
                dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION))
              }
            >
              <DeleteOutlined /> Clear
            </a>
          )}
        </div>
      </div>
      <AuthorizedQAHistoryList
        {...{
          patientInfo,
          setAskAQuestionLoading,
          page,
          pageSize,
          qaHistory,
          setQAHistory,
          onPaginationChange,
          setTotalQA,
          totalQA,
          callApi,
          setCallApi,
          isLoading,
          setIsLoading,
        }}
      />
    </div>
  );
};
