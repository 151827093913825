import { Button } from "antd";
import React, { useCallback, useEffect } from "react";
import { IPatient } from "../../../interfaces";
import {
  STATUS_CODE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { QuestionAnsweringService } from "../../../api/questionAnswering";
import { useDispatch } from "react-redux";
import { setQuestionsAnswers } from "../../../store";
import { useAuthorization } from "../../../hooks";
import "./askQuestion.scss";
import { Loading } from "../../../components";

interface Props {
  patientInfo: IPatient;
  setAskAQuestionLoading: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  pageSize: number;
  qaHistory: IQAHistory[];
  setQAHistory: React.Dispatch<React.SetStateAction<IQAHistory[]>>;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  setTotalQA: React.Dispatch<React.SetStateAction<number>>;
  totalQA: number;
  callApi: boolean;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QAHistoryList = ({
  setAskAQuestionLoading,
  patientInfo,
  qaHistory,
  setQAHistory,
  onPaginationChange,
  totalQA,
  setTotalQA,
  page,
  pageSize,
  callApi,
  setCallApi,
  isLoading,
  setIsLoading,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsAuthorized } = useAuthorization();
  const fetchQAHistory = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await QuestionAnsweringService.getQAHistory({
        offset: getOffsetFromPageAndPageSize(page, pageSize),
        limit: pageSize,
        patientId: patientInfo.patientId,
      });
      setQAHistory((prevState) => [...prevState, ...data.data]);
      setTotalQA(data.pagination.totalCount ?? 0);
    } catch (error: any) {
      if (error.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        navigate(-1);
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    navigate,
    page,
    pageSize,
    patientInfo.patientId,
    setIsAuthorized,
    setIsLoading,
    setQAHistory,
    setTotalQA,
  ]);

  const handleQuesClick = async (logId: number) => {
    setAskAQuestionLoading(true);
    try {
      const { data } = await QuestionAnsweringService.getSingleQa({
        logId,
        patientId: patientInfo.patientId,
      });
      dispatch(
        setQuestionsAnswers([
          {
            Answer: data.data.Answer,
            Question: data.data.Question,
          },
        ])
      );
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setAskAQuestionLoading(false);
    }
  };

  const showMoreClick = () => {
    onPaginationChange(page + 1, pageSize);
    setCallApi(true);
  };

  useEffect(() => {
    if (callApi) {
      fetchQAHistory();
      setCallApi(false);
    }
  }, [callApi, fetchQAHistory, setCallApi]);
  return (
    <>
      {qaHistory.length > 0 ? (
        <div className="question-item-list">
          {qaHistory.map((qa, index) => (
            <div
              className="cursor-pointer"
              key={`qa-history-${index}`}
              onClick={() => handleQuesClick(qa.Id)}
            >
              <h4 title={qa.Question} className="question-item text_ellipsis">
                {qa.Question}
              </h4>
            </div>
          ))}
          {qaHistory.length !== totalQA && (
            <div className="tc m-t">
              <Button
                className="outline"
                onClick={showMoreClick}
                loading={isLoading}
              >
                Show More
              </Button>
            </div>
          )}
        </div>
      ) : (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <p>
              No Chat history available now. Type your first question to begin
              the chat.
            </p>
          )}
        </>
      )}
    </>
  );
};
