import React from "react";
import { Tabs } from "antd";
import { PATIENT_INSIGHT_TABS_NEW } from "..";
const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const PatientInsightsTabs = ({ onChange, selectedTab }: Props) => {
  return (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(PATIENT_INSIGHT_TABS_NEW).map((key) => {
          // if (key === PATIENT_INSIGHT_TABS_NEW.ASK_A_QUESTION) {
          //   return (
          //     <TabPane
          //       tab={
          //         <div>
          //           {key} <span className="chip">BETA</span>
          //         </div>
          //       }
          //       key={key}
          //     />
          //   );
          // } else {
            return <TabPane tab={key} key={key} />;
          // }
        })}
      </Tabs>
    </div>
  );
};
