import { Card, Table } from "antd";
import React from "react";
import { ImmunizationHistory } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import {
  NoResultsFoundMessage,
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
  PatientInsightTile,
} from "../tile-helper-components";
import { ColumnsType } from "antd/lib/table";
import { PATIENT_INSIGHT_TILES } from "..";

interface DataType {
  vaccination: string;
  date: string;
  key: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: "Vaccination",
    dataIndex: "vaccination",
    key: "vaccination",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

interface Props {
  data: ImmunizationHistory[];
  patientId: number;
}

export const ImmunizationHistoryCard = ({ data, patientId }: Props) => {
  const navigate = useNavigate();
  const mid = Math.ceil(data.length / 2);
  const firstHalf = data.splice(0, mid);
  const secondHalf = data.splice(-mid);

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title={PATIENT_INSIGHT_TILES.immunizationHistory}>
      <div className="patient-card tiles-max-height-table">
        {data.length > 0 ? (
          <Table
            // locale={{
            //   emptyText: (
            //     <NoResultsFoundMessage aboveEightyLength={data.length} belowEightyLength={0} />
            //   ),
            // }}
            columns={columns}
            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.vaccination) })}
            rowClassName={"cursor-pointer"}
            dataSource={data.map((a, index) => ({ ...a, key: `immunization-history-${index}` }))}
            pagination={false}
            className="cmn-table"
          />
        ) : (
          <NoResultsFoundMessage aboveEightyLength={data.length} belowEightyLength={0} />
        )}
        {/* {data.length !== 0 ? (
          <div>
            <div>
              <h5 className="data-title m-b">Vaccination and Date</h5>
            </div>
            <div className="tiles-max-height">
            {data?.map((item, index) => (
              <div
                key={`immunization-history-${index}`}
                onClick={() => handleSearchNavigate(item.vaccination)}
              >
                <PatientInsightTile text={item.vaccination} value={item.date} />
              </div>
            ))}
            </div>
          </div>
        ) : (
          <NoResultsFoundMessage aboveEightyLength={data.length} belowEightyLength={0} />
        )} */}
      </div>
    </PatientCardWrapper>
  );
};
