import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import {
  projectService,
  RecentDocument,
  RecentDocumentStatus,
} from "../../api";
import {
  AppPagination,
  Loading,
  NotAccessibleFallback,
} from "../../components";
import { setProjectDocumentDetail, openModal } from "../../store";
import {
  DOCUMENT_STATUS,
  PAGE_URL,
  getLocalDateTimeStringFromISO,
  MODAL_TYPE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
  STATUS_CODE,
} from "../../utils";
import { usePagination } from "../../hooks";
import { FILE_UPLOAD_INITIAL_STATUS } from "../project-detail";

interface Props {
  projectId: number;
  fetchDocuments: boolean;
  setFetchDocuments: Dispatch<SetStateAction<boolean>>;
}

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "#44C658";
    case DOCUMENT_STATUS.ERROR:
      return "red";
    default:
      return "#475569";
  }
};

interface DataType extends RecentDocument {
  key: React.Key;
  Status: string;
}

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction
): ColumnsType<DataType> => [
  {
    title: "Filename & ID",
    key: "FileName",
    dataIndex: "FileName",
    render: (item, record) => (
      <p
        className="cc flex cursor-pointer"
        onClick={() =>
          record.Status === DOCUMENT_STATUS.COMPLETED &&
          navigate(`${PAGE_URL.EVIDENCE_VIEWER}?fileId=${record.DocumentUUID}`)
        }
      >
        {item}
      </p>
    ),
  },
  {
    title: "Uploaded on ",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  },
  // {
  //   title: "Completed on ",
  //   key: "completedOn",
  //   dataIndex: "completedOn",
  //   render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  // },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text, value) => (
      <div className="status" style={{ color: getClass(value.Status) }}>
        <p style={{ color: getClass(value.Status) }}>{value.Status}</p>{" "}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, record: DataType) => (
      <div
        className="editDelete cursor-pointer"
        onClick={() => {
          dispatch(
            setProjectDocumentDetail({
              ...record,
            })
          );
          dispatch(
            openModal(MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS)
          );
        }}
      >
        <a>View More</a>
      </div>
    ),
  },
];

export const RecentDocuments = (props: Props) => {
  const { fetchDocuments, projectId, setFetchDocuments } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onPaginationChange, page, pageSize, pageSizeOptions } =
    usePagination();
  const [loading, setLoading] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [documents, setDocuments] = useState([] as RecentDocument[]);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const documentsList: DataType[] = documents.map((item) => {
    const documentStatusLength = item.DocumentProcessStatuses.length;

    let lastFileUploadedObj = {} as RecentDocumentStatus;
    for (let i = item.DocumentProcessStatuses.length - 1; i >= 0; i--) {
      if (
        item.DocumentProcessStatuses[i].Status === FILE_UPLOAD_INITIAL_STATUS
      ) {
        lastFileUploadedObj = item.DocumentProcessStatuses[i];
        break;
      }
    }
    const fileNameSplitArray = item.FileName.split("/");

    return {
      key: item.Id,
      Status:
        documentStatusLength > 0
          ? item.DocumentProcessStatuses[documentStatusLength - 1].Status
          : "",
      ...item,
      FileName: fileNameSplitArray[fileNameSplitArray.length - 1],
      createdAt: lastFileUploadedObj.updatedAt,
    };
  });

  const handleOnPaginationChange = useCallback(
    (updatedPage: number, updatedPageSize: number) => {
      onPaginationChange(updatedPage, updatedPageSize);
      setFetchDocuments(true);
    },
    [onPaginationChange, setFetchDocuments]
  );

  const fetchFiles = useCallback(async () => {
    try {
      if (projectId) {
        setLoading(true);
        const qs = `?project_id=${projectId}&offset=${getOffsetFromPageAndPageSize(
          page,
          pageSize
        )}&limit=${pageSize}`;
        const { data } = await projectService.getFiles(qs);
        setDocuments(data.data);
        setTotalDocuments(data.pagination?.totalCount || 0);
      }
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, projectId, setIsAuthorized]);

  useEffect(() => {
    if (fetchDocuments) {
      fetchFiles();
      setFetchDocuments(false);
    }
  }, [fetchDocuments, fetchFiles, setFetchDocuments]);

  return (
    // <div className="recent-document">
    //   <h2 className="basic-detail-title">Recent Documents</h2>
    <>
      {loading ? (
        <Loading />
      ) : !isAuthorized ? (
        <NotAccessibleFallback />
      ) : (
        <>
          <div className="client-table basic-detail-content">
            <Table
              columns={getColumns(dispatch, navigate)}
              dataSource={documentsList}
              pagination={false}
              loading={loading}
            />
          </div>
          <div className="tr m-t">
            <AppPagination
              {...{
                showSizeChanger: true,
                current: page,
                total: totalDocuments,
                pageSize,
                pageSizeOptions,
                onChange: handleOnPaginationChange,
              }}
            />
          </div>
        </>
      )}
    </>
    // </div>
  );
};
