import React from "react";
import { IconsProps } from "./Icons";

export const RoleIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 24 24"}
      width={width ? width : "24"}
      height={height ? height : "24"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        d="M13.5 3.75V0.75H10.5V3.75H4.5V23.25H19.5V3.75H13.5ZM12 1.5C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25C12.75 2.44891 12.671 2.63968 12.5303 2.78033C12.3897 2.92098 12.1989 3 12 3C11.8011 3 11.6103 2.92098 11.4697 2.78033C11.329 2.63968 11.25 2.44891 11.25 2.25C11.25 2.05109 11.329 1.86032 11.4697 1.71967C11.6103 1.57902 11.8011 1.5 12 1.5V1.5ZM18 21.75H6V5.25H10.5V6.75H13.5V5.25H18V21.75Z"
        fill="currentColor"
      />
      <path
        d="M12 8.25C9.9285 8.25 8.25 9.9285 8.25 12C8.25 14.0715 9.9285 15.75 12 15.75C14.0715 15.75 15.75 14.0715 15.75 12C15.75 9.9285 14.0715 8.25 12 8.25ZM12 14.25C10.7595 14.25 9.75 13.2405 9.75 12C9.75 10.7595 10.7595 9.75 12 9.75C13.2405 9.75 14.25 10.7595 14.25 12C14.25 13.2405 13.2405 14.25 12 14.25ZM9 17.25C8.60218 17.25 8.22064 17.408 7.93934 17.6893C7.65804 17.9706 7.5 18.3522 7.5 18.75V20.25H16.5V18.75C16.5 18.3522 16.342 17.9706 16.0607 17.6893C15.7794 17.408 15.3978 17.25 15 17.25H9Z"
        fill="currentColor"
      />
    </svg>
  );
};
