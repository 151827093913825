import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import {
  PaginationLeftArrowIcon,
  PaginationRightArrowIcon,
} from "../../assets/icons";
import { useUser } from "../../hooks";
import { DATA_ADMIN_PERMISSIONS, MODAL_TYPE } from "../../utils";
import { ProjectsList, PROJECTS_HEADING } from "./settings-page-aside";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import "./settings-page-aside.scss";
import { withAuthorization } from "../../components/hoc/withAuthorization";
const { Panel } = Collapse;

const AuhtorizedProjectList = withAuthorization(ProjectsList);

export const SettingsPageAside = () => {
  const dispatch = useDispatch();
  const { roleCode } = useUser();

  return (
    <>
      {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
        <>
          <h2 className="title flex jcsb">
            Projects
            <PlusOutlined
              className="flex-center add-btn"
              onClick={() => {
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
              }}
            />
          </h2>
        </>
      )}
      <div className="customize-accordion">
        <Collapse
          accordion
          defaultActiveKey={[PROJECTS_HEADING]}
          expandIconPosition="end"
          expandIcon={(e) =>
            e.isActive ? (
              <PaginationLeftArrowIcon />
            ) : (
              <PaginationRightArrowIcon />
            )
          }
        >
          <Panel header={PROJECTS_HEADING} key={PROJECTS_HEADING}>
            <AuhtorizedProjectList />
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
