import React from "react";
import { Col, Row } from "antd";
import {
  TopSearches,
  ProjectListInTableAndTile,
  ProjectAndPatientTile,
} from "./dashboard-container";
import "./Clinician-and-data-admin-container.scss";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const AuthorizedProjectAndPatientTile = withAuthorization(
  ProjectAndPatientTile
);

export const ClinicianAndDataAdminContainer = () => {
  return (
    <div className="dashboard">
      <div className="top-search-result">
        {/* <Row className="top-search-result-item" gutter={[21, 20]}>
          <AuthorizedProjectAndPatientTile />
        </Row> */}
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <div className="projectsList">
            <ProjectListInTableAndTile className="scroll-small" />
          </div>
        </Row>
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <Col span={24}>
            <h2>Top Searches</h2>
          </Col>
          <Col span={24}>
            <TopSearches />
          </Col>
        </Row>
      </div>
      {/* <Sidebar data={data} /> */}
    </div>
  );
};
