import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import { Button } from "antd";
import { AddEditProjectConfigurationProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";
import "./add-edit-project-configuration.scss";
import {
  AddRemoveTags,
  MandatoryFieldsText,
  SingleSlider,
} from "../../../components";
import { useUser } from "../../../hooks";
import { AddEditProjectRequest, projectService } from "../../../api";
import { ProjectMeta } from "../../../interfaces";
import { openNotificationWithIcon } from "../../../utils";

const FORM_KEYS = {
  title: "title",
  details: "details",
  columns: "columns",
  score: "score",
};

const AddEditProjectConfiguration = ({
  visibility,
  handleClose,
  editObj,
  isEdit,
  projectDetail,
  updateProjectDetail,
}: AddEditProjectConfigurationProps) => {
  const { clientId } = useUser();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(isEdit ? editObj.field_name : "");
  const [details, setDetails] = useState(
    isEdit ? editObj.field_content.details : []
  );
  const [columns, setColumns] = useState(
    isEdit ? editObj.field_content.columns : []
  );
  const [sliderValue, setSliderValue] = useState(
    isEdit ? editObj.field_content.threshold_confidence_score : 80
  );
  const [error, setError] = useState({
    title: "",
    details: "",
    columns: "",
    sliderValue: "",
  });

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    setError({ ...error, title: "" });
  };
  const handleDetailsChange = (values: string[]) => {
    setDetails(values);
    setError({ ...error, details: "" });
  };
  const isValidForm = () => {
    let isValid = true;
    let obj = {};
    if (title.length === 0) {
      obj = { ...obj, title: "This field is required" };
      isValid = false;
    }
    if (details.length === 0) {
      obj = { ...obj, details: "This field is required" };
      isValid = false;
    }
    setError({ ...error, ...obj });
    return isValid;
  };

  const handleSubmit = async () => {
    setError({ columns: "", details: "", sliderValue: "", title: "" });
    if (isValidForm()) {
      const meta: ProjectMeta = {
        field_content: {
          columns,
          details,
          threshold_confidence_score: sliderValue,
        },
        field_key: ["PROJECT_CONFIG", ...title.toUpperCase().split(" ")].join(
          "_"
        ),
        field_name: title,
      };
      const reqObj: AddEditProjectRequest = {
        description: projectDetail.Description,
        name: projectDetail.Name,
        s3Folder: projectDetail.s3Folder,
        meta: [meta],
      };
      setLoading(true);
      try {
        const { data } = await projectService.editOne(
          clientId,
          projectDetail.Id,
          reqObj
        );
        updateProjectDetail({ ...projectDetail, ...data.data });
        handleClose();
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Modal
      className="autonomizeModal addClient "
      centered
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{`${isEdit ? "Edit" : "Add a new"} insights field`}</h1>
      </div>
      <div className="addClient-content customize-ant-tag">
        <Form onSubmitCapture={handleSubmit} colon={false} requiredMark={false}>
          <Form.Item label="Title" name={FORM_KEYS.title}>
            <div>
              <Input
                value={title}
                onChange={handleTitleChange}
                disabled={isEdit}
              />
              {error.title && (
                <p className="ant-form-item-explain-error">{error.title}</p>
              )}
            </div>
          </Form.Item>
          <Form.Item label="Field Details" name={FORM_KEYS.details}>
            <div>
              <p className="normal-font-size">{"(eg: ICD-10 codes)"}</p>
              <AddRemoveTags
                setTags={handleDetailsChange}
                tags={details}
                newTagTitle="Add Detail"
                allowAddingNewTag
              />
              {error.details && (
                <p className="ant-form-item-explain-error">{error.details}</p>
              )}
            </div>
          </Form.Item>
          <Form.Item
            label="Field display columns (optional)"
            name={FORM_KEYS.columns}
          >
            <div>
              {error.columns && (
                <p className="ant-form-item-explain-error">{error.columns}</p>
              )}
              <AddRemoveTags
                setTags={setColumns}
                tags={columns}
                newTagTitle="Add Columns"
                allowAddingNewTag
              />
            </div>
          </Form.Item>
          <Form.Item name={FORM_KEYS.score}>
            <div>
              <p className="slider-text">{`Field confidence score: ${sliderValue}%`}</p>
              <SingleSlider
                setValue={setSliderValue}
                disabled={false}
                value={sliderValue}
              />
              {error.sliderValue && (
                <p className="ant-form-item-explain-error">
                  {error.sliderValue}
                </p>
              )}
            </div>
          </Form.Item>
          <Form.Item className="tr mandatory-text">
            <MandatoryFieldsText />
            <Button className="outline" htmlType="submit" loading={loading}>
              Save Details
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditProjectConfiguration;
