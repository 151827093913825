import React, { Dispatch, SetStateAction, useCallback } from "react";
import { UploadFile } from "antd";
import { projectService } from "../../../api";
import { FileUploader } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

interface Props {
  projectId: number;
  folderName: string;
  setFetchDocuments: Dispatch<SetStateAction<boolean>>;
}

export const UploadProjectDocuments = (props: Props) => {
  const { projectId, setFetchDocuments, folderName } = props;

  const isFileNameValid = useCallback((fileName: string) => {
    const pattern = /^\d+_.*/;
    return pattern.test(fileName);
  }, []);

  const onFileUpload = useCallback(
    async (file: UploadFile<any>) => {
      try {
        const { data } = await projectService.getS3UploadURL(
          projectId,
          file.name
        );
        await projectService.uploadFiles(data.response, file);
        await projectService.createDocument(
          projectId,
          `${folderName}/${file.name}`
        );
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    },
    [folderName, projectId]
  );

  const onFilesUploadComplete = useCallback(async () => {
    setFetchDocuments(true);
  }, [setFetchDocuments]);

  return (
    <FileUploader
      onSubmit={onFileUpload}
      validateFileName={isFileNameValid}
      onFilesUploadComplete={onFilesUploadComplete}
      validateFileNameErrorMessage={
        "Invalid file name format. Rename the file to start with a digit(s), followed by an underscore, and any characters"
      }
    />
  );
};
