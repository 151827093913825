import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
import { EVIDENCE_VIEWER_URL } from './tile-helper-components';
import { IPatient, DropdownOption } from '../../../interfaces';
import { PatientProjects, projectService } from '../../../api';
import { useFetchPatientStatusOptions } from '../../../hooks';
import { Dropdown, Loading } from '../../../components';
import { getLocalDateTimeStringFromISO } from '../../../utils';
import { PatientDetailCardProps } from '..';

const { Search } = Input;

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
}

export const PatientInsightActionItems = (props: Props) => {
  const { patientInfo, selectedProject, setReviewStatusMap } = props;
  const { projectStatusInfo: patientStatusInfo } = patientInfo;
  const navigate = useNavigate();
  // const [reviewStatus, setReviewStatus] = useState(patientStatusInfo?.projectStatusName);
  // const projectId = selectedProject.Id ? selectedProject.Id : -1;

  // const {
  //   fetchPatientStatusList,
  //   setPatientStatusLoading,
  //   patientStatusLoading,
  //   patientStatusOptions,
  // } = useFetchPatientStatusOptions();

  const handleSearchSubmit = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientInfo.patientId}`,
    );
  };

  // const handleStatusChange = async (value: string, option: DropdownOption) => {
  //   setPatientStatusLoading(true);
  //   const { data } = await projectService.updatePatientStatus(
  //     option.id,
  //     patientStatusInfo?.patientProjectId
  //   );
  //   setReviewStatusMap([
  //     { heading: "Reviewed By", value: data.data.updatedBy },
  //     {
  //       heading: "Reviewed On",
  //       value: getLocalDateTimeStringFromISO(data.data.updatedAt),
  //     },
  //   ]);
  //   setReviewStatus(option.value);
  //   setPatientStatusLoading(false);
  // };

  // useEffect(() => {
  //   const getPatientStatusOptions = async () => {
  //     if (projectId !== -1) {
  //       await fetchPatientStatusList();
  //     }
  //   };
  //   getPatientStatusOptions();
  // }, [fetchPatientStatusList, projectId]);

  // useEffect(() => {
  //   setReviewStatus(patientStatusInfo?.projectStatusName);
  // }, [patientStatusInfo?.projectStatusName]);

  return (
    <>
      <div className="flex gp aic">
        <div className="custom-search">
          <Search
            className="record-search"
            placeholder="Search within Records"
            style={{ width: '350px' }}
            onSearch={handleSearchSubmit}
          />
        </div>
        {/* {projectId !== -1 && (
          <div className="ai-select-box" style={{ flex: "auto", maxWidth: 210 }}>
            <Dropdown
              value={reviewStatus}
              options={patientStatusOptions}
              loading={patientStatusLoading}
              onSelect={handleStatusChange}
              notFoundContent={patientStatusLoading && <Loading />}
            />
          </div>
        )}{" "} */}
      </div>
    </>
  );
};
