import { Card, Table } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  NoResultsFoundMessage,
  PatientCardWrapper,
  PatientDetailCardInfo,
  EVIDENCE_VIEWER_URL,
} from "../tile-helper-components";
import { MedicationHistory } from "../../../../interfaces";
import { PATIENT_INSIGHT_TILES } from "..";
import { INSIGHT_TYPE } from "../../../evidence-viewer/evidence-viewer-container";
import { ColumnsType } from "antd/lib/table";
import { Attribute } from "../../../../api";

interface Props {
  medication_history: MedicationHistory[];
  patientId: number;
}

interface DataType {
  key: string;
  medication: string;
  mode: string;
  dosage: string;
  frequency: string;
  form: string;
  strength: string;
  duration: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Medication",
    dataIndex: "medication",
    key: "mdeication",
  },
  {
    title: "Mode",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Dosage",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
    key: "frequency",
  },
  {
    title: "Form",
    dataIndex: "form",
    key: "form",
  },
  {
    title: "Strength",
    dataIndex: "strength",
    key: "strength",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
];

const getEntryWithMaxScore = (data: Attribute[]): string => {
  if (!data || data.length === 0) return "-";
  let maxScore = -Infinity;
  let maxScoreObject = data[0];

  for (let i = 0; i < data.length; i++) {
    if (data[i].Score > maxScore) {
      maxScore = data[i].Score;
      maxScoreObject = data[i];
    }
  }
  return maxScoreObject.Text;
};

export const PatientMedicationHistory = ({ patientId, medication_history }: Props) => {
  const tableData: DataType[] = [];
  const navigate = useNavigate();

  medication_history.forEach((history, index) => {
    const obj: DataType = {
      key: `medication-history-${index}`,
      dosage: getEntryWithMaxScore(history.dosage),
      frequency: getEntryWithMaxScore(history.frequency),
      medication: history.generic,
      mode: getEntryWithMaxScore(history.route_or_mode),
      form: getEntryWithMaxScore(history.form),
      strength: getEntryWithMaxScore(history.strength),
      duration: getEntryWithMaxScore(history.duration),
    };
    tableData.push(obj);
  });

  const handleSearchNavigate = (str: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICATION}`
    );
  };
  return (
    <>
      <PatientCardWrapper title={PATIENT_INSIGHT_TILES.medicationHistory}>
        {/* <div className="flex gp-10 aic tiles-max-height">
          <NoResultsFoundMessage
            aboveEightyLength={medication_history.length}
            belowEightyLength={0}
          />
          {medication_history.map((item, index) => (
            <Link
              key={`medication-history-item-${index}`}
              to={`${EVIDENCE_VIEWER_URL}&search=${item.generic}&patient_id=${patientId}&insight_type=${INSIGHT_TYPE.MEDICATION}`}
            >
              <div className="patient-insight-element">{item.generic}</div>
            </Link>
          ))}
        </div> */}
        <div className="blue flex patient-card tiles-max-height-table">
          {tableData.length > 0 ? (
            <Table
              // locale={{
              //   emptyText: (
              //     <div>
              //       <p className="placeholder-text">No entries found.</p>
              //     </div>
              //   ),
              // }}
              columns={columns}
              dataSource={tableData}
              pagination={false}
              className="cmn-table"
              rowClassName={"cursor-pointer"}
              onRow={(record) => ({ onClick: () => handleSearchNavigate(record.medication) })}
            />
          ) : (
            <NoResultsFoundMessage
              aboveEightyLength={medication_history.length}
              belowEightyLength={0}
            />
          )}
        </div>
      </PatientCardWrapper>
    </>
  );
};
