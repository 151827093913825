import { useCallback, useState } from "react";
import {
  EvidenceFileData,
  EvidencePageResponseInfo,
  evidenceViewerService,
  SearchOccurrence,
} from "../api";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

interface Props {
  fileId: string;
  searchKeywords: string | null;
}

export const useFetchEvidenceViewer = ({ fileId, searchKeywords }: Props) => {
  const [currPage, setCurrPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [searchOccurenceLoading, setSearchOccurenceLoading] = useState(true);
  const [pageData, setPageData] = useState({} as EvidencePageResponseInfo);
  const [fileData, setFileData] = useState({} as EvidenceFileData);
  const [occurencesList, setOccurencesList] = useState(
    [] as SearchOccurrence[]
  );
  const [isAuthorized, setIsAuthorized] = useState(true);

  const fetchFile = useCallback(async () => {
    try {
      setFileData({ fileName: "", url: "" });
      const { data } = await evidenceViewerService.getFile(fileId);
      if (data.fileData) setFileData(data.fileData);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    }
  }, [fileId]);

  const fetchPage = useCallback(async () => {
    try {
      setPageLoading(true);
      const { data } = await evidenceViewerService.getPageData(
        currPage,
        fileId
      );
      setPageData(data);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setPageLoading(false);
    }
  }, [currPage, fileId]);

  const fetchSearchOccurences = useCallback(
    async (tileType: string | null) => {
      try {
        setCurrPage(1);
        setSearchOccurenceLoading(true);
        const { data } = await evidenceViewerService.getOccurrencesPages(
          fileId,
          searchKeywords,
          tileType
        );
        const { occurencesList: list } = data;
        setOccurencesList(list.sort((a, b) => a.pageNumber - b.pageNumber));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setSearchOccurenceLoading(false);
      }
    },
    [fileId, searchKeywords]
  );

  return {
    fetchFile,
    fetchPage,
    currPage,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    fileData,
    setCurrPage,
    fetchSearchOccurences,
    occurencesList,
    isAuthorized,
    setIsAuthorized,
  };
};
