import React from "react";
import { Project } from "../../../interfaces";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { useDispatch } from "react-redux";
import { EditIcon } from "../../../assets/icons";
import { openModal, setProjectEditDelete, updateProject } from "../../../store";
import { Switch } from "antd";
import { projectService } from "../../../api";

interface Props {
  project: Project;
}

export const ProjectActions = ({ project }: Props) => {
  const dispatch = useDispatch();
  const onChange = async (status: boolean) => {
    try {
      const { data } = await projectService.disableEnable(
        project.OrganizationId,
        status,
        Number(project.Id)
      );
      dispatch(updateProject(data.data));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };
  return (
    <div className="editDelete flex gp">
      {/* <Link to={`${PAGE_URL.PROJECT_DETAIL}/${project.Id}`}>View More</Link> */}
      <EditIcon
        className={project.status ? "cursor-pointer" : "cursor-disable"}
        onClick={() => {
          if (project.status) {
            dispatch(setProjectEditDelete({ id: Number(project.Id), isEdit: true }));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
          }
        }}
      />
      <Switch size="small" checked={project.status} onChange={onChange} />
    </div>
  );
};
