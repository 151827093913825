import React, { Dispatch, useEffect, useState } from "react";
import { Button, Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  openModal,
  RootState,
  setProjectEditDelete,
  setProjects,
  updateDeleteConfirmation,
  updateProject,
} from "../../store";
import {
  DATA_ADMIN_PERMISSIONS,
  getLocalDateTimeStringFromISO,
  MODAL_TYPE,
  openNotificationWithIcon,
  PAGE_URL,
  STATUS_CODE,
} from "../../utils";
import { projectService } from "../../api";
import { useAuthorization, useUser } from "../../hooks";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { EditIcon } from "../../assets/icons";
import "./project-list.scss";

interface Props {
  atDashboard?: boolean;
}

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  details: string;
  clientId: number;
  status: boolean;
  createdOn: string;
}

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  role: string
): ColumnsType<DataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Created On",
    key: "createdOn",
    dataIndex: "createdOn",
  },
  {
    title: "Action",
    key: "details",
    dataIndex: "details",
    render: (text, value) => {
      const onChange = async (status: boolean) => {
        try {
          const { data } = await projectService.disableEnable(
            value.clientId,
            status,
            Number(value.key)
          );
          dispatch(updateProject(data.data));
        } catch (err: any) {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      };

      return (
        <div className="editDelete jcc flex gp">
          <Link to={`${PAGE_URL.PROJECT_DETAIL}/${value.key}`}>View More</Link>
          {DATA_ADMIN_PERMISSIONS.includes(role) && (
            <>
              <EditIcon
                className={value.status ? "cursor-pointer" : "cursor-disable"}
                onClick={() => {
                  if (value.status) {
                    dispatch(
                      setProjectEditDelete({
                        id: Number(value.key),
                        isEdit: true,
                      })
                    );
                    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
                  }
                }}
              />
              <Switch size="small" checked={value.status} onChange={onChange} />
            </>
          )}
        </div>
      );
    },
  },
];

export const ProjectsList = ({ atDashboard }: Props) => {
  const dispatch = useDispatch();
  const { roleCode, clientId } = useUser();
  const { projects } = useSelector((state: RootState) => state.data);
  const { setIsAuthorized } = useAuthorization();

  const { id: projectId } = useSelector(
    (state: RootState) => state.data.projects
  );
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );
  const [loading, setLoading] = useState(false);
  const projectData: DataType[] = projects.list.map((item) => {
    const { Description, Id, Name, OrganizationId, status, createdAt } = item;
    return {
      key: Id,
      name: Name,
      description: Description,
      details: "",
      clientId: OrganizationId,
      status,
      createdOn: getLocalDateTimeStringFromISO(createdAt),
    };
  });

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await projectService.getAll(clientId);
        const { data: projectList } = data;
        dispatch(setProjects(projectList));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    if(clientId) fetch();
  }, [clientId, dispatch, setIsAuthorized]);

  useEffect(() => {
    const deleteProjectAPI = async () => {
      try {
        await projectService.deleteOne(clientId, projectId);
        dispatch(deleteProject(projectId));
        dispatch(updateDeleteConfirmation(false));
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    };

    if (isDeleteConfirmed && projectId !== -1) deleteProjectAPI();
  }, [projectId, dispatch, isDeleteConfirmed, clientId]);

  return (
    <>
      {!atDashboard && (
        <div className="flex aie jcsb m-t">
          <h3 className="no-padding">Projects</h3>
          {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
            <Button
              className="outline"
              onClick={() => {
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
              }}
            >
              Add New Project
            </Button>
          )}
        </div>
      )}
      <div className="client-table project-list basic-detail-content">
        <Table
          dataSource={projectData}
          columns={getColumns(dispatch, roleCode)}
          loading={loading}
          pagination={false}
        ></Table>
      </div>
    </>
  );
};
