import React, { useState } from "react";
import { UploadProjectDocuments } from "../project-detail-components";
import { RecentDocuments } from "../../tables";

interface Props {
  projectId: number;
  folderName: string;
}

export const ProjectDetailDocuments = ({ projectId, folderName }: Props) => {
  const [fetchDocuments, setFetchDocuments] = useState(true);

  return (
    <>
      <UploadProjectDocuments
        {...{ projectId, setFetchDocuments, folderName }}
      />
      <div className="recent-document">
        <h2 className="basic-detail-title">Recent Documents</h2>
        <RecentDocuments
          {...{ projectId, fetchDocuments, setFetchDocuments }}
        />
      </div>
    </>
  );
};
